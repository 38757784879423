import React, { useMemo } from 'react';

import { VideoInfo } from '../../Types/VideoInfo';
import { SecondsToTime } from '../../Classes/Utilities';

import Card from '../Card/Card';
import UsersLikes from '../UsersLikes/UsersLikes';

import classes from './VideoCard.module.css';

export type VideoCardProps = {
    cardSize?: 'large' | 'medium' | 'small';
    videoInfo: VideoInfo;
    isReadOnly: boolean;
    onVideoPlay: () => void;
    onVideoPlus: () => void;
    onVideoDelete: () => void;
};

function VideoCard(props: VideoCardProps) {
    const { cardSize, videoInfo, isReadOnly, onVideoPlay, onVideoPlus, onVideoDelete } = props;
    const videoDurationStr = useMemo(() => videoInfo.durationStr ?? SecondsToTime(videoInfo.duration), [videoInfo]);

    return (
        <Card
            cardShape="rect"
            cardRoundStyle="top-rounded"
            cardSize={cardSize}
            imgPath={videoInfo.thumbnailImgPath}
            imgOverlays={[
                {
                    showMode: 'onHover',
                    overlayChildren: (
                        <>
                            <button
                                className={`play_rls ${classes.icon} ${classes.play_item_icon}`}
                                style={{ background: `url('Images/play-w-48.ico') center no-repeat` }}
                                onClick={onVideoPlay}
                            ></button>
                            <button
                                className={`plus_rls ${classes.icon} ${classes.plus_item_icon}`}
                                style={{ background: `url('Images/plus-w-48.ico') center no-repeat` }}
                                onClick={onVideoPlus}
                            ></button>
                        </>
                    )
                },
                {
                    showMode: 'always',
                    className: 'empty',
                    overlayChildren: <div className={classes.videoDuration}>{videoDurationStr}</div>
                }
            ]}
            detailsCustomCSS={{ padding: 0 }}
            details={
                <div className={classes.videoToolbar}>
                    <UsersLikes itemType="video" itemId={videoInfo.id} usersLikeInfo={videoInfo.usersLikeInfo}></UsersLikes>
                    {!isReadOnly && (
                        <div>
                            <button className={'fa fa-lg fa-solid fa-trash'} title="Delete Video" onClick={onVideoDelete}></button>
                        </div>
                    )}
                </div>
            }
        ></Card>
    );
}

export default VideoCard;
