import React, { useState } from 'react';

import { UserInfo } from '../../../Types/User';
import { ChatData, Message } from '../../../Types/Chat';

import MenuButton from '../../../Components/MenuButton/MenuButton';
import UserAvatar from '../../../Components/UserAvatar/UserAvatar';
import Preloader from '../../../Components/Preloader/Preloader';
import Title from '../../../Components/Title/Title';
import Chat from '../../../Components/Chat/Chat';

import classes from './ChatMessages.module.css';

export type UsersChatsProps = {
    chatData?: ChatData;
    chatParticipant?: UserInfo;
    showBackToChatsButton?: boolean;
    onBackToChats: () => void;
    onNewMessage: (newMessage: Message) => void;
};

function ChatMessages(props: UsersChatsProps) {
    const { chatData, chatParticipant, showBackToChatsButton = false, onBackToChats, onNewMessage } = props;

    const [filteredChatData, setFilteredChatData] = useState<ChatData>();

    const handleNewMessage = async (newMessage: Message) => {
        if (newMessage) {
            setFilteredChatData(undefined);
            onNewMessage(newMessage);
        }
    };

    return (
        <div>
            <Title>
                <div className={classes.messagesHeader}>
                    <div>
                        {showBackToChatsButton && (
                            <button className={classes.backToChatsBtn} title={'Back to Chats'} onClick={onBackToChats}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>
                        )}
                        {chatParticipant && <UserAvatar avatarSize="tiny" viewMode="inline" userInfo={chatParticipant}></UserAvatar>}
                    </div>
                    <MenuButton
                        buttonSize="small"
                        menuSections={[
                            {
                                menuItems: [
                                    {
                                        text: filteredChatData ? 'Show All Messages' : 'Show Only Recommendations',
                                        onClick: () => {
                                            if (filteredChatData) {
                                                setFilteredChatData(undefined);
                                            } else if (chatData) {
                                                setFilteredChatData({
                                                    participants: chatData.participants,
                                                    messages: chatData.messages.filter(m => m.type === 'RC')
                                                });
                                            }
                                        }
                                    }
                                ]
                            }
                        ]}
                    ></MenuButton>
                </div>
            </Title>
            <div className={classes.messagesList}>
                {chatData ? (
                    <Chat chatData={filteredChatData ?? chatData} isMessageInputVisible={true} onNewMessage={handleNewMessage}></Chat>
                ) : (
                    <Preloader viewMode="Inline"></Preloader>
                )}
            </div>
        </div>
    );
}

export default ChatMessages;
