import React, { useState } from 'react';

import Slider from '../../Slider/Slider';

export type VolumeControlProps = {
    volumeValue: number;
    sliderCustomCSS?: React.CSSProperties;
    onVolumeChange: (volumeValue: number, isMuted: boolean) => void;
};

function VolumeControl(props: VolumeControlProps) {
    const { volumeValue, sliderCustomCSS, onVolumeChange } = props;

    const [isMuted, setIsMuted] = useState<boolean>(false);

    const getVloumeIcon = (): string => {
        if (isMuted || volumeValue === 0) {
            return 'fa-volume-mute';
        } else if (volumeValue < 30) {
            return 'fa-volume-off';
        } else if (volumeValue < 50) {
            return 'fa-volume-low';
        }

        return 'fa-volume-high';
    };

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center', margin: '0 .5rem', columnGap: '.35rem', height: '100%' }}>
            <button
                style={{ textAlign: 'start' }}
                onClick={() => {
                    const isMutedNewStatus = !isMuted;
                    setIsMuted(isMutedNewStatus);
                    onVolumeChange(volumeValue, isMutedNewStatus);
                }}
            >
                <i className={`fa fa-lg ${getVloumeIcon()}`}></i>
            </button>
            <Slider
                curVal={volumeValue}
                sliderHandlerShape="circle"
                sliderCustomCSS={sliderCustomCSS}
                onChange={volume => {
                    onVolumeChange(volume, false);
                }}
            ></Slider>
        </div>
    );
}

export default VolumeControl;
