import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { User } from '../../Classes/User';
import { TrackInfo } from '../../Types/Track';
import { PlaylistInfo } from '../../Types/PlaylistInfo';
import { BELLINO_BASE_URL, FetchPageData, GetNowUTCDate } from '../../Classes/Utilities';
import { createPlaylist, deletePlaylist, updatePlaylist } from '../../Services/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';
import useDeviceData from '../../hooks/useDeviceData';

import NotFound from '../NotFound/NotFound';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';

import Playlists from './partials/Playlists';
import PlaylistTracks from './partials/PlaylistTracks';
import PlaylistEditorDialog, { PlaylistEditorMode } from './partials/PlaylistEditorDialog';

import classes from './UsersPlaylists.module.css';

function UsersPlaylistsPage(props: any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { userSessionData } = useUserSessionData();
    const { isMobileView } = useDeviceData();
    const { updatePageMetdata } = usePageMetdata();

    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;
    const playlistID = searchParams.get('pid');
    const isMyPlaylits = userSessionData.userInfo?.id === userID;

    const isLoadingRef = useRef<boolean>();
    const [isLoading, setIsLoading] = useState(true);

    const [user, setUser] = useState<User>(new User());
    const [userPlaylists, setUserPlaylists] = useState<PlaylistInfo[]>([]);
    const [selectedPlaylistTracks, setSelectedPlaylistTracks] = useState<TrackInfo[]>([]);
    const [playlistEditorDialogMode, setPlaylistEditorDialogMode] = useState<PlaylistEditorMode>('hidden');
    const [displayMode, setDisplayMode] = useState<'Playlists' | 'PlaylistTracks' | undefined>();

    const loadPlaylists = useCallback(() => {
        if (!isLoadingRef.current && userID) {
            isLoadingRef.current = true;
            setIsLoading(true);
            setSelectedPlaylistTracks([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/userplaylists?uid=${userID}&pid=${playlistID ?? ''}`).then((response: any) => {
                if (response.success) {
                    const user = new User(response.result?.userInfo);

                    updatePageMetdata({
                        title: `${user.fullName} - Playlists`,
                        description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`
                    });

                    setUser(user);
                    setUserPlaylists(response.result?.playlists);
                    setSelectedPlaylistTracks(
                        response.result?.selectedPlaylistTracks?.map((pt: any) => {
                            return {
                                id: pt.id,
                                name: pt.name,
                                artists: pt.artists ?? [],
                                videos: [
                                    {
                                        externalId: pt.video,
                                        duration: pt.duration
                                    }
                                ]
                            } as TrackInfo;
                        })
                    );
                } else {
                    setUser(new User());
                    setUserPlaylists([]);
                    setSelectedPlaylistTracks([]);
                }

                isLoadingRef.current = false;
                setIsLoading(false);
            });
        }
    }, [playlistID, userID, updatePageMetdata]);

    useEffect(() => {
        loadPlaylists();
    }, [loadPlaylists]);

    useEffect(() => {
        if (isMobileView) {
            setDisplayMode(playlistID ? 'PlaylistTracks' : 'Playlists');
        } else {
            setDisplayMode(undefined);
        }
    }, [isMobileView, playlistID]);

    const selectedPlaylist = playlistID ? userPlaylists.filter(p => p.id.toString() === playlistID)?.[0] : userPlaylists?.[0];

    if ((!userID && userSessionData.isReady) || (!user.isInit && !isLoading)) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!user.isInit}>
            <Section>
                <Title>
                    <Breadcrumbs breadcrumbsItems={[{ text: user.fullName, href: `/user?uid=${user.id}` }, { text: 'Playlists' }]}></Breadcrumbs>
                </Title>
                <div className={isMobileView ? classes.container : classes.containerGrid}>
                    {selectedPlaylist ? (
                        <>
                            {displayMode !== 'PlaylistTracks' && (
                                <Playlists
                                    userID={userID}
                                    userPlaylists={userPlaylists}
                                    selectedPlaylistId={selectedPlaylist.id}
                                    onPlaylistSelected={() => {
                                        setDisplayMode('PlaylistTracks');
                                    }}
                                    onNewPlaylist={() => {
                                        setPlaylistEditorDialogMode('create');
                                    }}
                                ></Playlists>
                            )}
                            {displayMode !== 'Playlists' && (
                                <PlaylistTracks
                                    selectedPlaylist={selectedPlaylist}
                                    selectedPlaylistTracks={selectedPlaylistTracks}
                                    isMyPlaylits={isMyPlaylits}
                                    isLoading={isLoading}
                                    showBackToPlaylistsButton={isMobileView}
                                    onBackToPlaylists={() => {
                                        setDisplayMode('Playlists');
                                    }}
                                    onChangePlaylistEditorMode={mode => {
                                        setPlaylistEditorDialogMode(mode);
                                    }}
                                ></PlaylistTracks>
                            )}
                        </>
                    ) : (
                        <p>{`${user.fullName} has no playlists`}</p>
                    )}
                </div>
            </Section>

            {selectedPlaylist && (
                <PlaylistEditorDialog
                    mode={playlistEditorDialogMode}
                    playlistName={selectedPlaylist.name}
                    onCreate={async (newPlaylistName, playlistTracksIDS) => {
                        const playlistID = await createPlaylist(newPlaylistName, playlistTracksIDS);

                        if (playlistID) {
                            const newPlaylistsArray = [
                                {
                                    id: playlistID,
                                    name: newPlaylistName,
                                    date: new Date(),
                                    numOfTracks: playlistTracksIDS?.length ?? 0
                                } as PlaylistInfo
                            ].concat(userPlaylists);

                            setUserPlaylists(newPlaylistsArray);

                            // Update URL to view new created playlist
                            searchParams.set('pid', playlistID);
                            setSearchParams(searchParams);
                        }
                    }}
                    onUpdate={async (newPlaylistName, newPlaylistTracksIds) => {
                        const newPlaylistTracklistIds = selectedPlaylistTracks.map(t => t.id).concat(newPlaylistTracksIds ?? []);
                        const isUpdated = await updatePlaylist(selectedPlaylist.id, newPlaylistName, newPlaylistTracklistIds);

                        if (isUpdated) {
                            const newPlaylistsArray = userPlaylists.map(p => {
                                return p.id === selectedPlaylist.id
                                    ? { ...p, name: newPlaylistName, tracksIds: newPlaylistTracklistIds, date: GetNowUTCDate() }
                                    : p;
                            });

                            if (newPlaylistTracksIds?.length) {
                                //TODO: maybe find better way to append new tracks without reload all playlists
                                loadPlaylists();
                            } else {
                                setUserPlaylists(newPlaylistsArray);
                            }
                        }
                    }}
                    onDelete={async () => {
                        const isDeleted = await deletePlaylist(selectedPlaylist.id);

                        if (isDeleted) {
                            const newPlaylistsArray = userPlaylists.filter(p => p.id !== selectedPlaylist.id);
                            setUserPlaylists(newPlaylistsArray);

                            if (searchParams.has('pid')) {
                                searchParams.delete('pid');
                                setSearchParams(searchParams);
                            }
                        }
                    }}
                    onCloseDialog={() => {
                        setPlaylistEditorDialogMode('hidden');
                    }}
                ></PlaylistEditorDialog>
            )}
        </AppMainContainer>
    );
}

export default UsersPlaylistsPage;
