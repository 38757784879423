import React from 'react';

import { UserSessionData } from '../../Types/User';
import { fbInit, fbLogIn } from '../../Services/External/Facebook';

import Button from '../Button/Button';

import classes from './FacebookLogin.module.css';

interface FacebookLoginProps {
    buttonViewMode?: 'icon' | 'standard';
    onSigninClick?: () => void;
    onSigninCompleted: (userSessionData?: UserSessionData) => void;
}

function FacebookLogin(props: FacebookLoginProps) {
    const { buttonViewMode = 'standard', onSigninClick, onSigninCompleted } = props;

    const onFbSigninClick = async () => {
        onSigninClick?.();

        if (await fbInit()) {
            const userSessionData = await fbLogIn();
            onSigninCompleted?.(userSessionData);
        }
    };

    return buttonViewMode === 'standard' ? (
        <Button className={classes.facebookLoginStandard} viewMode="fancy" onClick={onFbSigninClick}>
            Sign in with Facebook
        </Button>
    ) : (
        <Button
            className={classes.facebookLoginIcon}
            viewMode="fancy"
            btnShape="circle"
            ariaLabel="Sign in with Facebook"
            onClick={onFbSigninClick}
        >
            <i className="fa-brands fa-facebook-f fa-lg"></i>
        </Button>
    );
}

export default FacebookLogin;
