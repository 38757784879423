import React, { useMemo } from 'react';

import { ChatInfo } from '../../../Types/Chat';
import { getImageFullPath, htmlToText } from '../../../Classes/Utilities';

import Button from '../../../Components/Button/Button';
import LiveDate from '../../../Components/LiveDate/LiveDate';
import GenericList, { GenericListItem } from '../../../Components/GenericList/GenericList';

import classes from './UsersChats.module.css';

export type UsersChatsProps = {
    userChats: ChatInfo[];
    selectedChatId?: string;
    onChatSelected: () => void;
    onNewChat: () => void;
};

function UsersChats(props: UsersChatsProps) {
    const { selectedChatId, userChats, onChatSelected, onNewChat } = props;

    const chatsAsListItems = useMemo(
        () =>
            userChats.map(chat => {
                return {
                    titleStr: chat.title,
                    title: chat.title,
                    subTitle: (
                        <div className={classes.lastMessagePreview}>
                            {htmlToText(chat.lastMessage.replaceAll('<br/>', '\n').replaceAll('<br>', '\n').replaceAll('\n', ' '))}
                        </div>
                    ),
                    subInfo: <LiveDate utcDate={chat.lastMessageDate}></LiveDate>,
                    image: getImageFullPath(chat.imgPath),
                    imageShape: 'circle',
                    href: `/messages?fid=${chat.id}`,
                    isSelectedItem: chat.id.toString() === selectedChatId?.toString(),
                    onItemClick: onChatSelected
                } as GenericListItem;
            }),
        [selectedChatId, userChats, onChatSelected]
    );

    return (
        <div>
            <div className="mt-100 mb-100">
                <Button viewMode="classic" onClick={onNewChat}>
                    + New Message
                </Button>
            </div>
            <div className={classes.conversionsList}>
                <GenericList items={chatsAsListItems} spaceBetweenItems={true}></GenericList>
            </div>
        </div>
    );
}

export default UsersChats;
