import React from 'react';

import { PlayerStateMode } from '../../../Types/Player';
import YouTubePlayer from '../../Youtube/Youtube';

export type YouTubeMusicPlayerProps = {
    youtubeVideoId?: string;
    forceMusicPlayerReload: boolean;
    onPlayerReady: (player: any) => void;
    onPlayerError: (error: any) => void;
    onPlayerStateChange: (state: PlayerStateMode) => void;
};

function YouTubeMusicPlayer(props: YouTubeMusicPlayerProps) {
    const { youtubeVideoId = '', forceMusicPlayerReload, onPlayerReady, onPlayerError, onPlayerStateChange } = props;

    return (
        <YouTubePlayer
            videoId={youtubeVideoId}
            forceReload={forceMusicPlayerReload}
            onReady={player => {
                onPlayerReady(player);
            }}
            onStateChange={state => {
                onPlayerStateChange(state);
            }}
            onError={error => {
                onPlayerError(error);
            }}
        ></YouTubePlayer>
    );
}

export default YouTubeMusicPlayer;
