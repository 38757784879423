import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserInfo } from '../../Types/User';
import { User } from '../../Classes/User';
import { DEFAULT_AVATAR_IMG } from '../../Classes/Utilities';

import Button from '../Button/Button';
import Image from '../Image/Image';

import FriendshipDialog, { FriendshipDialogMode } from '../../Pages/_partials/FriendshipDialog';

import classes from './UserAvatar.module.css';

interface BaseUserAvatarProps {
    userInfo: UserInfo;
    viewMode: 'DetailedBox' | 'inline' | 'image-only';
    avatarSize?: 'large' | 'medium' | 'small' | 'x-small' | 'tiny';
    avatarURL?: string;
}

type InlineViewProps = BaseUserAvatarProps & {
    viewMode: 'inline';
    userNameNode?: 'none' | 'first-name' | 'full-name';
};

type DetailedBoxViewProps = BaseUserAvatarProps & {
    viewMode: 'DetailedBox';
    friendshipButton?: 'add' | 'unfriend';
};

type ImageOnlyViewProps = BaseUserAvatarProps & {
    viewMode: 'image-only';
    isImageLinkDisabled?: boolean;
};

export type UserAvatarProps = InlineViewProps | ImageOnlyViewProps | DetailedBoxViewProps;

const UserAvatar = (props: UserAvatarProps) => {
    const { userInfo, avatarURL, viewMode } = props;

    const avatarSize = props.avatarSize ?? 'medium';
    const avatarSizePx =
        avatarSize === 'large' ? 195 : avatarSize === 'medium' ? 150 : avatarSize === 'small' ? 65 : avatarSize === 'x-small' ? 45 : 30;

    const user = useMemo(() => new User(userInfo), [userInfo]);
    const userFullName = user.fullName;
    const userURL = avatarURL ?? user.userURL;

    const [friendshipDialogMode, setFriendshipDialogMode] = useState<FriendshipDialogMode>('hidden');

    const containerStyle = {
        width: `calc(${avatarSizePx}px + 1rem)`
    };

    const avatarImage = (isSacaled: boolean = false) => (
        <Image
            alt={user.fullName}
            src={user.userImg}
            srcFallback={DEFAULT_AVATAR_IMG}
            viewWidth={avatarSizePx}
            viewHeight={avatarSizePx}
            viewMode="background-image"
            imageClass={`circle ${isSacaled ? 'scale-trans' : ''}`}
        ></Image>
    );

    if (viewMode === 'image-only') {
        const { isImageLinkDisabled } = props as ImageOnlyViewProps;

        return (
            <>
                {isImageLinkDisabled === true ? (
                    avatarImage()
                ) : (
                    <Link to={userURL} className={classes.photoLink}>
                        {avatarImage(true)}
                    </Link>
                )}
            </>
        );
    }

    if (viewMode === 'inline') {
        const { userNameNode } = props as InlineViewProps;

        return (
            <Link to={userURL} className={classes.inlineView}>
                {avatarImage()}
                {userNameNode !== 'none' && <span>{userNameNode === 'first-name' ? user.firstName : userFullName}</span>}
            </Link>
        );
    }

    const { friendshipButton } = props as DetailedBoxViewProps;

    return (
        <>
            <div className={classes.container} style={containerStyle}>
                <Link to={userURL} className={classes.photoLink}>
                    {avatarImage(true)}
                </Link>
                <div className={classes.profileDetails}>
                    <Link to={userURL} className="fw-400">
                        <span className={classes.profileName}>{userFullName}</span>
                    </Link>
                    <div className="text-gray">{user.userDetails ?? <br />}</div>
                    {friendshipButton && (
                        <div className="mt-100">
                            {friendshipButton === 'add' ? (
                                <Button
                                    onClick={() => {
                                        setFriendshipDialogMode('add-friend');
                                    }}
                                >
                                    Add Friend
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        setFriendshipDialogMode('unfriend');
                                    }}
                                >
                                    Unfriend
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <FriendshipDialog
                mode={friendshipDialogMode}
                friend={user}
                onCloseDialog={() => setFriendshipDialogMode('hidden')}
            ></FriendshipDialog>
        </>
    );
};

export default UserAvatar;
