import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChatData, ChatInfo } from '../../Types/Chat';
import { getUserChats, getChatData } from '../../Services/Messages';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';
import useDeviceData from '../../hooks/useDeviceData';

import NotFound from '../NotFound/NotFound';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';

import NewMessageDialog from '../_partials/NewMessageDialog';
import UsersChats from './Partials/UsersChats';
import ChatMessages from './Partials/ChatMessages';

import classes from './UsersMessages.module.css';

function UsersMessagesPage(props: any) {
    usePageMetdata({ title: 'Messages' });

    const [searchParams] = useSearchParams();
    const friendID = searchParams.get('fid');

    const { userSessionData } = useUserSessionData();
    const { isMobileView } = useDeviceData();

    const [userChats, setUserChats] = useState<ChatInfo[]>([]);
    const [chatData, setChatData] = useState<ChatData>();
    const [displayMode, setDisplayMode] = useState<'ChatsList' | 'ChatMessages' | undefined>();

    const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);

    const isLoadingChats = useRef<boolean>();
    const isLoadingSelectedChat = useRef<boolean>();

    const selectedChatId = friendID ? friendID : !isMobileView && userChats.length > 0 ? userChats[0].id : undefined;

    useEffect(() => {
        if (!isLoadingChats.current) {
            isLoadingChats.current = true;

            getUserChats().then(chatsInfo => {
                setUserChats(chatsInfo);
                isLoadingChats.current = false;
            });
        }
    }, []);

    useEffect(() => {
        if (!isLoadingSelectedChat.current && selectedChatId) {
            isLoadingSelectedChat.current = true;
            setChatData(undefined);

            getChatData(selectedChatId).then(chatData => {
                setChatData(chatData);
                isLoadingSelectedChat.current = false;
            });
        }

        if (isMobileView) {
            setDisplayMode(selectedChatId ? 'ChatMessages' : 'ChatsList');
        } else {
            setDisplayMode(undefined);
        }
    }, [isMobileView, selectedChatId]);

    const chatParticipant = useMemo(
        () => chatData?.participants?.find(p => p.id.toString() === selectedChatId?.toString()),
        [chatData?.participants, selectedChatId]
    );

    if (userSessionData.isReady && !userSessionData.userInfo?.id /* || (!chatParticipant && isLoadingSelectedChat.current === false)*/) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={isLoadingChats.current !== false}>
            <Section>
                <div className={isMobileView ? classes.chatsOrMessages : classes.chatsAndMessages}>
                    {displayMode !== 'ChatMessages' && (
                        <UsersChats
                            selectedChatId={selectedChatId}
                            userChats={userChats}
                            onChatSelected={() => {
                                setDisplayMode('ChatMessages');
                            }}
                            onNewChat={() => {
                                setIsNewMessageDialogOpen(true);
                            }}
                        ></UsersChats>
                    )}
                    {displayMode !== 'ChatsList' && (
                        <ChatMessages
                            chatData={chatData}
                            chatParticipant={chatParticipant}
                            showBackToChatsButton={isMobileView}
                            onBackToChats={() => {
                                setDisplayMode('ChatsList');
                            }}
                            onNewMessage={newMessage => {
                                setChatData(prev => {
                                    return {
                                        participants: prev?.participants,
                                        messages: prev?.messages
                                            ? [...prev.messages.filter(m => m.id.toString() !== newMessage.id.toString()), newMessage]
                                            : [newMessage]
                                    } as ChatData;
                                });
                            }}
                        ></ChatMessages>
                    )}
                </div>
            </Section>
            <NewMessageDialog
                isVisible={isNewMessageDialogOpen}
                onCloseDialog={() => {
                    setIsNewMessageDialogOpen(false);
                }}
            ></NewMessageDialog>
        </AppMainContainer>
    );
}

export default UsersMessagesPage;
