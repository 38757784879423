import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TrackInfo } from '../Types/Track';
import { PlayerStateMode } from '../Types/Player';
import { MusicPlayerViewMode } from '../Types/MusicPlayerViewMode';
import { SecondsToTime } from '../Classes/Utilities';

export type MusicPlayerState = {
    viewMode: MusicPlayerViewMode;
    viewHeight: string;
    playStatus: PlayerStateMode;
    currentTrackIndex: number;
    tracklist: TrackInfo[];
};

export type SetViewModeAction = { type: 'SET_VIEW_MODE'; payload: MusicPlayerViewMode };
export type SetStatusAction = { type: 'SET_STATUS'; payload: PlayerStateMode };
export type SetCurrentTrackAction = { type: 'SET_CURRENT_TRACK'; payload: number };
export type PlayTrackAction = { type: 'PLAY_TRACK'; payload: TrackInfo };
export type AppendTrackAction = { type: 'APPEND_TRACK'; payload: TrackInfo };
export type RemoveTrackAction = { type: 'REMOVE_TRACK'; payload: number };
export type PlayPlaylistAction = { type: 'PLAY_PLAYLIST'; payload: TrackInfo[] };
export type AppendPlaylistAction = { type: 'APPEND_PLAYLIST'; payload: TrackInfo[] };
export type UpdateTrackVideoDurationAction = { type: 'UPDATE_TRACK_VIDEO_DURATION'; payload: { externalVideoId: string; duration: number } };

export type MusicPlayerAction =
    | SetViewModeAction
    | SetStatusAction
    | SetCurrentTrackAction
    | PlayTrackAction
    | AppendTrackAction
    | RemoveTrackAction
    | PlayPlaylistAction
    | AppendPlaylistAction
    | UpdateTrackVideoDurationAction;

const playerViewHeightByMode = (viewMode: MusicPlayerViewMode) => {
    if (viewMode === 'normal') return '15.4rem';
    if (viewMode === 'small') return '2.7rem';

    return '0px';
};

const defaultMusicPlayerState: MusicPlayerState = {
    viewMode: 'none',
    viewHeight: '0',
    playStatus: 'paused',
    currentTrackIndex: -1,
    tracklist: []
};

const musicPlayerSlice = createSlice({
    name: 'musicPlayer',
    initialState: defaultMusicPlayerState,
    reducers: {
        setViewMode(state, action: PayloadAction<MusicPlayerViewMode>) {
            const viewMode = action.payload;
            state.viewMode = viewMode;
            state.viewHeight = playerViewHeightByMode(viewMode);
        },
        setStatus(state, action: PayloadAction<PlayerStateMode>) {
            state.playStatus = action.payload;
        },
        setCurrentTrack(state, action: PayloadAction<number>) {
            state.currentTrackIndex = action.payload;
            state.playStatus = 'playing';
        },
        playTrack(state, action: PayloadAction<TrackInfo>) {
            const trackInfo = { ...action.payload };
            trackInfo.videos = trackInfo.videos?.map(video => {
                return { ...video, durationStr: video.durationStr ?? SecondsToTime(video.duration) };
            });

            state.tracklist = [trackInfo];
            state.viewMode = 'normal';
            state.viewHeight = playerViewHeightByMode('normal');
            state.playStatus = 'playing';
            state.currentTrackIndex = 0;
        },
        appendTrack(state, action: PayloadAction<TrackInfo>) {
            const trackInfo = { ...action.payload };
            trackInfo.videos = trackInfo.videos?.map(video => {
                return { ...video, durationStr: video.durationStr ?? SecondsToTime(video.duration) };
            });

            state.tracklist = state.tracklist.concat([trackInfo]);

            if (state.viewMode === 'none') {
                state.viewMode = 'normal';
                state.viewHeight = playerViewHeightByMode('normal');
            }
        },
        removeTrack(state, action: PayloadAction<number>) {
            if (action.payload === state.currentTrackIndex) {
                state.currentTrackIndex = -1;
                state.playStatus = 'paused';
            } else if (action.payload < state.currentTrackIndex) {
                state.currentTrackIndex--;
            }

            state.tracklist = state.tracklist.filter((track, index) => index !== action.payload);
        },
        playNextTrack(state) {
            if (state.currentTrackIndex < state.tracklist.length - 1) {
                state.currentTrackIndex = state.currentTrackIndex + 1;
                state.playStatus = 'playing';
            } else {
                state.currentTrackIndex = -1;
                state.playStatus = 'ended';
            }
        },
        playPlaylist(state, action: PayloadAction<TrackInfo[]>) {
            const tracksInfo = [...action.payload]?.map(track => {
                const trackInfo = { ...track };
                const trackVideos = trackInfo.videos?.map(video => {
                    return { ...video, durationStr: video.durationStr ?? SecondsToTime(video.duration) };
                });

                return { ...trackInfo, videos: trackVideos };
            });

            state.tracklist = tracksInfo;
            state.viewMode = 'normal';
            state.viewHeight = playerViewHeightByMode('normal');
            state.playStatus = 'playing';
            state.currentTrackIndex = 0;
        },
        appendPlaylist(state, action: PayloadAction<TrackInfo[]>) {
            const tracksInfo = [...action.payload]?.map(track => {
                const trackInfo = { ...track };
                const trackVideos = trackInfo.videos?.map(video => {
                    return { ...video, durationStr: video.durationStr ?? SecondsToTime(video.duration) };
                });

                return { ...trackInfo, videos: trackVideos };
            });

            state.tracklist = state.tracklist.concat(tracksInfo);

            if (state.viewMode === 'none') {
                state.viewMode = 'normal';
                state.viewHeight = playerViewHeightByMode('normal');
            }
        },
        updateVideoDuration(state, action: PayloadAction<{ externalVideoId: string; duration: number }>) {
            state.tracklist = state.tracklist.map(track => {
                return {
                    ...track,
                    videos: track.videos?.map(trackVideo => {
                        if (trackVideo.externalId === action.payload.externalVideoId) {
                            const trackVideoDuration = action.payload.duration;
                            return { ...trackVideo, duration: trackVideoDuration, durationStr: SecondsToTime(trackVideoDuration) };
                        }

                        return trackVideo;
                    })
                };
            });
        }
    }
});

export const {
    setViewMode,
    setStatus,
    setCurrentTrack,
    playTrack,
    appendTrack,
    removeTrack,
    playNextTrack,
    playPlaylist,
    appendPlaylist,
    updateVideoDuration
} = musicPlayerSlice.actions;

export default musicPlayerSlice.reducer;
