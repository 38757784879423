import React, { useMemo } from 'react';

import { PlaylistInfo } from '../../../Types/PlaylistInfo';

import Button from '../../../Components/Button/Button';
import LiveDate from '../../../Components/LiveDate/LiveDate';
import GenericList, { GenericListItem } from '../../../Components/GenericList/GenericList';

import classes from './Playlists.module.css';

export type PlaylistsProps = {
    userID?: string;
    userPlaylists: PlaylistInfo[];
    selectedPlaylistId: string;
    onPlaylistSelected: () => void;
    onNewPlaylist: () => void;
};

function Playlists(props: PlaylistsProps) {
    const { userID, userPlaylists, selectedPlaylistId, onPlaylistSelected, onNewPlaylist } = props;

    const playlistsAsListItems = useMemo(
        () =>
            userPlaylists?.map(playlist => {
                return {
                    titleStr: playlist.name,
                    title: playlist.name,
                    subTitle: `${playlist.numOfTracks} Tracks`,
                    subInfo: <LiveDate utcDate={playlist.date}></LiveDate>,
                    image: '/Images/playlist.png',
                    href: `/playlists?uid=${userID}&pid=${playlist.id}`,
                    isSelectedItem: playlist.id === selectedPlaylistId,
                    onItemClick: onPlaylistSelected
                } as GenericListItem;
            }),
        [userID, userPlaylists, selectedPlaylistId, onPlaylistSelected]
    );

    return (
        <div>
            <div className="mt-100 mb-100">
                <Button viewMode="classic" onClick={onNewPlaylist}>
                    + New Playlist
                </Button>
            </div>
            <div className={classes.playlists}>
                <GenericList items={playlistsAsListItems} spaceBetweenItems={false}></GenericList>
            </div>
        </div>
    );
}

export default Playlists;
