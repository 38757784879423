import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { removeUserNotification } from '../../redux/userSlice';

import { UserInfo } from '../../Types/User';
import { ChatData, Message } from '../../Types/Chat';
import { FormatDate, GetFixedForamtDate, GetTime, IsSameDay } from '../../Classes/Utilities';
import { AnalyzedText } from '../../Classes/jsxUtilities';
import useUserSessionData from '../../hooks/useUserSessionData';
import { sendMessage, updateMessageStatus } from '../../Services/Messages';

import UserAvatar from '../UserAvatar/UserAvatar';
import RecommendationMessage from './partials/RecommendationMessage';

import classes from './Chat.module.css';

export type ChatProps = {
    chatData: ChatData;
    isMessageInputVisible: boolean;
    onNewMessage: (newMessage: Message) => void;
};

function Chat(props: ChatProps) {
    const { chatData, isMessageInputVisible, onNewMessage } = props;
    const { messages, participants } = chatData;

    const dispatch = useDispatch<AppDispatch>();

    const lastMessage = useRef<Message>();
    const chatMessagesListRef = useRef(null);
    const textareaRef = useRef(null);
    const isUpdatingMessageStatus = useRef(false);

    const { userSessionData } = useUserSessionData();
    const myUserId = userSessionData.userInfo?.id?.toString();

    // Sort messages by date ASC + handle links
    const sortedMessages = useMemo(
        () =>
            messages
                .sort((m1, m2) => {
                    return GetFixedForamtDate(m1.date).getTime() - GetFixedForamtDate(m2.date).getTime();
                })
                .map(m => {
                    return { ...m, messageJSX: AnalyzedText(m.message) };
                }),
        [messages]
    );

    // New readed messages IDs
    const newReadedMessagesIDs = useMemo(
        () => messages.filter(m => m.senderId?.toString() !== myUserId?.toString() && m.status === 'Sent').map(m => m.id),
        [messages, myUserId]
    );

    const onUserMessageChange = async (event: React.KeyboardEvent) => {
        if (event && event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            const textareaElement = event?.target as HTMLTextAreaElement;
            const newMessageText = textareaElement?.value?.trim();
            const friendID = chatData.participants.find(p => p.id.toString() !== myUserId)?.id;

            if (newMessageText?.length > 0 && myUserId && friendID) {
                event.preventDefault();

                const newMessage: Message = {
                    id: Date.now().toString(),
                    date: new Date(),
                    message: newMessageText,
                    senderId: myUserId,
                    status: 'Snding',
                    type: 'PM'
                };

                onNewMessage(newMessage);
                scrollToBottom();

                textareaElement.value = '';
                textareaElement.blur();
                textareaElement.focus();

                const newMsgId = await sendMessage(friendID, newMessageText);

                if (newMsgId) {
                    //newMessage.id = newMsgId;
                    newMessage.status = 'Sent';
                    onNewMessage(newMessage);
                }
            }
        }
    };

    const scrollToBottom = (delayMs: number = 0) => {
        const chatMessagesList = chatMessagesListRef?.current ? (chatMessagesListRef.current as HTMLUListElement) : null;

        if (chatMessagesList) {
            setTimeout(() => {
                chatMessagesList.scrollTop = chatMessagesList.scrollHeight;
                chatMessagesList.style.visibility = 'visible';
            }, delayMs);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    useEffect(() => {
        if (newReadedMessagesIDs.length > 0) {
            dispatch(removeUserNotification(newReadedMessagesIDs));

            const updateMessages = async () => {
                if (!isUpdatingMessageStatus.current) {
                    isUpdatingMessageStatus.current = true;
                    //TODO: create new API (api/messages) method to update multiple messages
                    await Promise.all(newReadedMessagesIDs.map(msgID => updateMessageStatus(msgID, 'Seen')));
                    isUpdatingMessageStatus.current = false;
                }
            };

            updateMessages();
        }
    }, [dispatch, newReadedMessagesIDs]);

    if (messages.length <= 0 || participants.length <= 0) {
        return null;
    }

    return (
        <div className={classes.chatContainer}>
            <ul ref={chatMessagesListRef}>
                {sortedMessages.map((messageInfo, index) => {
                    const messageDate = GetFixedForamtDate(messageInfo.date);
                    const messageTime = GetTime(messageDate);

                    let isNewDayMesage = false;
                    let isUnreadMesage = false;

                    if (lastMessage?.current && !IsSameDay(GetFixedForamtDate(lastMessage.current.date), messageDate)) {
                        isNewDayMesage = true;
                    }

                    if (
                        messageInfo.senderId?.toString() !== myUserId &&
                        messageInfo.status === 'Sent' &&
                        lastMessage.current?.status === 'Seen'
                    ) {
                        isUnreadMesage = true;
                    }

                    lastMessage.current = messageInfo;

                    return (
                        <React.Fragment key={index}>
                            {isNewDayMesage && (
                                <li className={classes.chatTitle}>
                                    <span>{FormatDate(messageInfo.date, 'Short')}</span>
                                </li>
                            )}
                            {isUnreadMesage && (
                                <li className={classes.chatTitle}>
                                    <span>Unread Messages</span>
                                </li>
                            )}
                            <li
                                className={
                                    userSessionData.userInfo?.id?.toString() === messageInfo.senderId?.toString() ? classes.outMessage : ''
                                }
                            >
                                <UserAvatar
                                    userInfo={participants.find(p => p.id.toString() === messageInfo.senderId.toString()) as UserInfo}
                                    avatarSize="x-small"
                                    viewMode="image-only"
                                    isImageLinkDisabled={true}
                                ></UserAvatar>
                                <div className={classes.message}>
                                    <div className={classes.messageContent}>
                                        {messageInfo.type === 'RC' && messageInfo.recommendation && (
                                            <div className="mb-100">
                                                <RecommendationMessage recommendationInfo={messageInfo.recommendation}></RecommendationMessage>
                                            </div>
                                        )}
                                        <div>{messageInfo.messageJSX}</div>
                                    </div>
                                    <div className={classes.messageDate}>{messageTime}</div>
                                </div>
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
            {isMessageInputVisible && (
                <div className={classes.messagesInput}>
                    <textarea ref={textareaRef} placeholder="Write a message..." onKeyDown={onUserMessageChange}></textarea>
                </div>
            )}
        </div>
    );
}

export default Chat;
