import React from 'react';

import { MusicPlayerViewMode } from '../../../../Types/MusicPlayerViewMode';
import { PlayerStateMode } from '../../../../Types/Player';
import { TrackInfo } from '../../../../Types/Track';

import TimeSlider from '../TimeSlider';
import VolumeControl from '../VolumeControl';
import PlayControlButtonsProps from '../PlayControlButtons';
import YouTubeMusicPlayer from '../YouTubeMusicPlayer';
import MusicPlayerTracklist from '../MusicPlayerTracklist';

import classes from './MusicPlayerPC.module.css';

export type MusicPlayerPCProps = {
    viewMode: MusicPlayerViewMode;
    viewHeight: string;
    videoId: string;
    forceMusicPlayerReload: boolean;
    volumeValue: number;
    timeValue: number;
    timeDuration: number;
    tracklist: TrackInfo[];
    currentTrackIndex: number;
    playStatus: PlayerStateMode;
    onPlayPause: () => void;
    onPreviousTrack: () => void;
    onNextTrack: () => void;
    onPlayTrack: (trackIndex: number) => void;
    onRemoveTrack: (trackIndex: number) => void;
    onPlayerReady: (player: any) => void;
    onPlayerError: (error: any) => void;
    onPlayerStateChange: (state: PlayerStateMode) => void;
    onPlayerViewModeChange: (viewMode: MusicPlayerViewMode) => void;
    onVolumeChange: (volumeValue: number, isMuted: boolean) => void;
    onTimeChange: (timeValue: number) => void;
};

function MusicPlayerPC(props: MusicPlayerPCProps) {
    const {
        viewMode,
        viewHeight,
        videoId,
        forceMusicPlayerReload,
        volumeValue,
        timeValue,
        timeDuration,
        tracklist,
        currentTrackIndex,
        playStatus,
        onPlayPause,
        onPreviousTrack,
        onNextTrack,
        onPlayTrack,
        onRemoveTrack,
        onPlayerReady,
        onPlayerError,
        onPlayerStateChange,
        onPlayerViewModeChange,
        onVolumeChange,
        onTimeChange
    } = props;

    return (
        <div className={`${classes.musicPlayer} ${viewMode === 'normal' ? classes.open : ''}`} style={{ height: viewHeight }}>
            <div>
                <div className={classes.playerToolbar}>
                    <div>{`Track ${currentTrackIndex + 1}/${tracklist.length}`}</div>
                    <TimeSlider
                        timeValue={timeValue}
                        timeDuration={timeDuration}
                        customCSS={{ border: 0, backgroundColor: 'rgb(34, 34, 34)', margin: '0 1rem' }}
                        onTimeChange={onTimeChange}
                    ></TimeSlider>
                    <PlayControlButtonsProps
                        playStatus={playStatus}
                        onPlayPause={onPlayPause}
                        onPreviousTrack={onPreviousTrack}
                        onNextTrack={onNextTrack}
                    ></PlayControlButtonsProps>
                    <VolumeControl
                        volumeValue={volumeValue}
                        sliderCustomCSS={{ border: 0, backgroundColor: 'rgb(34, 34, 34)', width: '50px' }}
                        onVolumeChange={onVolumeChange}
                    ></VolumeControl>
                    <button
                        className={`fa fa-lg ${viewMode === 'normal' ? 'fa-compress' : 'fa-expand'}`}
                        title={viewMode === 'normal' ? 'Minimize' : 'Expand'}
                        onClick={() => {
                            onPlayerViewModeChange(viewMode === 'normal' ? 'small' : 'normal');
                        }}
                    ></button>
                </div>
                <div className={classes.playerTrackslist}>
                    <MusicPlayerTracklist
                        tracklist={tracklist}
                        currentTrackIndex={currentTrackIndex}
                        onPlayTrack={onPlayTrack}
                        onRemoveTrack={onRemoveTrack}
                    ></MusicPlayerTracklist>
                </div>
            </div>
            <div className={classes.youtubeContainer}>
                <YouTubeMusicPlayer
                    youtubeVideoId={videoId}
                    forceMusicPlayerReload={forceMusicPlayerReload}
                    onPlayerReady={onPlayerReady}
                    onPlayerError={onPlayerError}
                    onPlayerStateChange={onPlayerStateChange}
                ></YouTubeMusicPlayer>
            </div>
        </div>
    );
}

export default MusicPlayerPC;
