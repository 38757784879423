import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Image.module.css';

export type ImageSize = {
    width: number;
    height: number;
};

export type ImageProps = {
    src: string;
    srcFallback?: string;
    alt?: string;
    width?: number;
    height?: number;
    viewWidth?: number;
    viewHeight?: number;
    viewMode?: 'normal' | 'background-image';
    imageStyle?: React.CSSProperties;
    imageClass?: string;
    children?: React.ReactNode;
    onImageClick?: () => void;
};

function ImageComp(props: ImageProps) {
    const {
        src,
        srcFallback,
        alt = 'presentation',
        width = 0,
        height = 0,
        viewMode = 'normal',
        imageStyle,
        imageClass,
        children,
        onImageClick
    } = props;
    const { viewWidth = width, viewHeight = height } = props;

    const imgLoadingStatus = useRef<string>();
    const imgLoadedSrc = useRef<string>();
    const imageElement = useRef<HTMLElement | HTMLDivElement | HTMLSpanElement | HTMLImageElement>(null);

    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const [imgSrc, setImgSrc] = useState<string>();
    const [isInView, setIsInView] = useState(false);

    const onImageLoaded = useCallback(() => {
        imgLoadingStatus.current = 'loaded';
        imgLoadedSrc.current = src;
        setImgSrc(src);
        setIsImgLoaded(true);
    }, [src]);

    const onImageLoadError = useCallback(() => {
        if (srcFallback) {
            imgLoadingStatus.current = 'loaded';
            imgLoadedSrc.current = srcFallback;
            setImgSrc(srcFallback);
            setIsImgLoaded(true);
        }
    }, [srcFallback]);

    useEffect(() => {
        if (
            src &&
            imgLoadingStatus.current !== 'loading' &&
            imgLoadedSrc.current !== src &&
            (!srcFallback || imgLoadedSrc.current !== srcFallback) &&
            isInView
        ) {
            imgLoadingStatus.current = 'loading';
            setIsImgLoaded(false);
            setImgSrc(src);

            if (viewMode !== 'normal') {
                var image = new Image();
                image.onerror = onImageLoadError;
                image.onload = onImageLoaded;
                image.src = src;
            }
        }
    }, [src, srcFallback, viewMode, isInView, onImageLoaded, onImageLoadError]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                } else {
                    setIsInView(false);
                }
            },
            {
                threshold: 0.1 // 10% of the element needs to be visible for it to be considered in view
            }
        );

        const imgElement = imageElement.current;

        if (imgElement) {
            observer.observe(imgElement);
        }

        return () => {
            if (imgElement) {
                observer.unobserve(imgElement);
            }
        };
    }, []);

    if (viewMode === 'background-image') {
        const finalImageStyle: React.CSSProperties = {
            ...imageStyle,
            width: viewWidth ? `${viewWidth}px` : undefined,
            height: viewHeight ? `${viewHeight}px` : undefined,
            cursor: onImageClick ? 'pointer' : '',
            backgroundImage: isImgLoaded ? `url(${imgSrc})` : undefined
        };

        return children ? (
            <div
                ref={imageElement as React.RefObject<HTMLDivElement>}
                className={`center-cropped-img ${imageClass ?? ''} ${isImgLoaded ? '' : 'lazy-load'}`}
                style={finalImageStyle}
                onClick={onImageClick}
            >
                {children}
            </div>
        ) : (
            <span
                ref={imageElement as React.RefObject<HTMLSpanElement>}
                className={`center-cropped-img ${imageClass ?? ''} ${isImgLoaded ? '' : 'lazy-load'}`}
                style={finalImageStyle}
                onClick={onImageClick}
            ></span>
        );
    }

    return (
        <span className={classes.imgContainer}>
            {!isImgLoaded && (
                <span
                    ref={imageElement as React.RefObject<HTMLSpanElement>}
                    className={`${imageClass ?? ''} lazy-load`}
                    onClick={onImageClick}
                    style={{
                        width: `${viewWidth}px`,
                        height: `${viewHeight}px`
                    }}
                ></span>
            )}
            {imgSrc && (
                <img
                    ref={imageElement as React.RefObject<HTMLImageElement>}
                    src={imgSrc}
                    alt={alt}
                    width={viewWidth}
                    height={viewHeight}
                    className={`${imageClass ?? ''}`}
                    onClick={onImageClick}
                    onLoad={onImageLoaded}
                    onError={onImageLoadError}
                    style={{ opacity: isImgLoaded ? 1 : 0 }}
                ></img>
            )}
        </span>
    );
}

export default ImageComp;
