import React, { useEffect } from 'react';

import { UserSessionData } from '../../Types/User';
import { GOOGLE_OAUTH_ID } from '../../Services/External/Google';
import { signinGoogleUser } from '../../Services/User';
/*
import Button from '../Button/Button';
import GoogleIcon from './GoogleIcon';

import classes from './GoogleLogin.module.css';
*/
interface GoogleLoginProps {
    buttonViewMode?: 'icon' | 'standard';
    buttonShape?: 'circle' | 'rounded';
    buttonSize?: 'large' | 'medium' | 'small';
    onSigninClick?: () => void;
    onSigninCompleted: (userSessionData?: UserSessionData) => void;
}

function GoogleLogin(props: GoogleLoginProps) {
    const { buttonViewMode = 'standard', buttonShape = 'circle', buttonSize = 'large', /*onSigninClick,*/ onSigninCompleted } = props;

    /*const onGoogleSigninClick = async () => {
        onSigninClick?.();

        const windowObj = window as any;

        if (windowObj.google && windowObj.google.accounts.id) {
            windowObj.google.accounts.id.disableAutoSelect(); // Disable auto One Tap suppression
            windowObj.google.accounts.id.prompt();
        }
    };*/

    const parseJWT = (token: string) => {
        try {
            const decodedJWT = JSON.parse(atob(token.split('.')[1]));
            return decodedJWT;
        } catch (error) {
            console.error('Failed to parse JWT:', error);
            return undefined;
        }
    };

    useEffect(() => {
        if (!document.getElementById('google-client-script')) {
            const windowObj = window as any;

            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.id = 'google-client-script';
            script.onerror = () => {
                console.error('Failed to load the Google Sign-In script.');
            };
            script.onload = () => {
                if (windowObj.google) {
                    windowObj.google.accounts.id.initialize({
                        client_id: GOOGLE_OAUTH_ID,
                        ux_mode: 'popup', // 'popup' | 'redirect'
                        context: 'signin',
                        //auto_prompt: false,
                        scope: 'profile email',
                        callback: async (response: any) => {
                            //console.log('Google Sign-In Response:', response);
                            const decodedInfo = parseJWT(response.credential);
                            const userData = await signinGoogleUser(decodedInfo);
                            onSigninCompleted(userData);
                        }
                    });

                    windowObj.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
                        theme: 'outline',
                        type: buttonViewMode,
                        shape: buttonShape === 'rounded' ? 'pill' : buttonShape,
                        size: buttonSize
                    });
                }
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [buttonShape, buttonSize, buttonViewMode, onSigninCompleted]);

    return <div id="google-signin-button" style={{ display: 'inline-block', height: '3.1rem', width: '3.1rem' }} />;
    /*
    return (
        <div>
            {buttonViewMode === 'standard' ? (
                <Button viewMode="fancy" onClick={onGoogleSigninClick}>
                    Sign in with Google
                </Button>
            ) : (
                <Button
                    className={classes.googleLoginIcon}
                    viewMode="fancy"
                    btnColor="white"
                    btnShape={buttonShape}
                    ariaLabel="Sign in with Google"
                    onClick={onGoogleSigninClick}
                >
                    <GoogleIcon />
                </Button>
            )}
        </div>
    );
    */
}

export default GoogleLogin;
