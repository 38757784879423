import { UserSessionData } from '../../Types/User';
import { signinFacebookUser } from '../User';

const windowObj = window as any;

export const fbInit = (): Promise<boolean> => {
    return new Promise(resolve => {
        if (windowObj.FB) {
            resolve(true);
        } else {
            windowObj.fb_login_status = null;
            windowObj.fbAsyncInit = () => {
                windowObj.FB.init({
                    appId: '315246785339762', // Bellino ID
                    xfbml: true, // parse social plugins on this page
                    cookie: true, // enable cookies to allow the server to access the session
                    version: 'v2.10'
                });

                windowObj.FB.getLoginStatus((response: any) => {
                    //console.log('statusChangeCallback response = ', response);
                    windowObj.fb_access_token = response?.authResponse?.accessToken ?? '';
                    windowObj.fb_login_status = response?.status;
                });

                resolve(true);
            };

            (function (d, id) {
                let js: HTMLScriptElement;
                let fjs = d.getElementsByTagName('script')[0];

                if (d.getElementById(id)) {
                    return;
                }

                js = d.createElement('script');
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode?.insertBefore?.(js, fjs);
            })(document, 'facebook-jssdk');
        }
    });
};

export const getFacebookUserData = (): Promise<UserSessionData | undefined> => {
    return new Promise(resolve => {
        let userData: UserSessionData | undefined = undefined;

        try {
            windowObj.FB.api(
                '/me?fields=id,email,name,gender,first_name,last_name,picture.type(large),link,location{location}',
                { access_token: windowObj.fb_access_token },
                async (response: any) => {
                    //console.log('facebook information response = ', response);
                    userData = await signinFacebookUser(response);
                    resolve(userData);
                }
            );
        } catch (ex) {
            resolve(userData);
        }
    });
};

export const fbLogIn = (): Promise<UserSessionData | undefined> => {
    return new Promise(async resolve => {
        let userData: UserSessionData | undefined = undefined;

        if (windowObj.fb_login_status === 'connected' && windowObj.fb_access_token) {
            // Already connected to facebook - fetch user data
            userData = await getFacebookUserData();
            resolve(userData);
        } else {
            // Login to facebook and fetch user data
            try {
                windowObj.FB.login(
                    (response: any) => {
                        if (response.authResponse) {
                            (async () => {
                                try {
                                    const userData = await getFacebookUserData();
                                    resolve(userData);
                                } catch (error) {
                                    console.error('Error fetching user data:', error);
                                    resolve(userData);
                                }
                            })();
                        }
                    },
                    { scope: 'public_profile,email,user_location' }
                );
            } catch (ex) {
                resolve(userData);
            }
        }
    });
};
