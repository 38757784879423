import React, { useEffect, useMemo, useState } from 'react';

import { TimerMode } from '../../../Types/Player';
import { SecondsToTime } from '../../../Classes/Utilities';

import Slider from '../../Slider/Slider';

export type TimeSliderProps = {
    timeValue: number;
    timeDuration: number;
    customCSS?: React.CSSProperties;
    onTimeChange: (timeValue: number) => void;
};

function TimeSlider(props: TimeSliderProps) {
    const { timeValue, timeDuration, customCSS, onTimeChange } = props;

    const [timeStr, setTimeStr] = useState('');
    const [timeMode, setTimerMode] = useState<TimerMode>('count-up');

    useEffect(() => {
        if (timeDuration > 0) {
            if (timeMode === 'count-up')
                setTimeStr(SecondsToTime(Math.round(timeValue - 0.1)) + ' / ' + SecondsToTime(Math.round(timeDuration - 0.1)));
            else if (timeMode === 'count-down') {
                setTimeStr(SecondsToTime(Math.round(timeValue - 0.1)));
            } else {
                setTimeStr(SecondsToTime(Math.round(timeDuration - timeValue - 0.1)));
            }
        } else {
            if (timeMode === 'count-up') {
                setTimeStr('00:00 / 00:00');
            } else {
                setTimeStr('00:00');
            }
        }
    }, [timeDuration, timeValue, timeMode]);

    // Time slider % value (0 - 100)
    const sliderValue = useMemo(() => (timeDuration > 0 ? (timeValue / timeDuration) * 100 : 0), [timeDuration, timeValue]);

    return (
        <>
            <div
                /*className={classes.playerTime}*/
                onClick={() => {
                    setTimerMode(timeMode === 'count-up' ? 'count-down' : 'count-up');
                }}
            >
                {timeStr}
            </div>
            <Slider
                curVal={sliderValue}
                sliderHandlerShape="none"
                sliderCustomCSS={customCSS}
                onChange={(timePos, isDragged) => {
                    !isDragged && onTimeChange(timePos);
                }}
            ></Slider>
        </>
    );
}

export default TimeSlider;
