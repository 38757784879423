import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../../redux/store';
import { appendPlaylist, playPlaylist } from '../../../redux/musicPlayerSlice';

import { Track } from '../../../Classes/Track';
import { TrackInfo } from '../../../Types/Track';
import { PlaylistInfo } from '../../../Types/PlaylistInfo';

import Link from '../../../Components/Link/Link';
import ArtistLink from '../../../Components/ArtistLink/ArtistLink';
import MenuButton from '../../../Components/MenuButton/MenuButton';
import Preloader from '../../../Components/Preloader/Preloader';
import ItemPlaybar from '../../../Components/ItemPlaybar/ItemPlaybar';
import TrackMenuButton from '../../../Components/MenuButton/TrackMenuButton';
import GenericList, { GenericListItem } from '../../../Components/GenericList/GenericList';

import classes from './PlaylistTracks.module.css';

export type PlaylistTracksProps = {
    selectedPlaylist: PlaylistInfo;
    selectedPlaylistTracks: TrackInfo[];
    isMyPlaylits: boolean;
    isLoading: boolean;
    showBackToPlaylistsButton: boolean;
    onBackToPlaylists: () => void;
    onChangePlaylistEditorMode: (mode: 'add-tracks' | 'rename' | 'delete') => void;
};

function PlaylistTracks(props: PlaylistTracksProps) {
    const {
        selectedPlaylist,
        selectedPlaylistTracks,
        isMyPlaylits,
        isLoading,
        showBackToPlaylistsButton,
        onBackToPlaylists,
        onChangePlaylistEditorMode
    } = props;

    const dispatch = useDispatch<AppDispatch>();

    const playlistTracksAsListItems = useMemo(
        () =>
            selectedPlaylistTracks?.map(track => {
                return {
                    titleStr: track.name,
                    title: <Link href={`/track?tid=${track.id}`}>{track.name}</Link>,
                    subTitle: <ArtistLink artists={track.artists}></ArtistLink>,
                    endSection: (
                        <div className={classes.trackToolbar}>
                            <ItemPlaybar viewSize="medium" itemType="Track" itemId={track.id} trackInfo={track}></ItemPlaybar>
                            <TrackMenuButton
                                buttonSize="medium"
                                buttonChildren={<i className="fa fa-ellipsis-v"></i>}
                                track={new Track(track)}
                                menuXPosition="Right"
                            ></TrackMenuButton>
                        </div>
                    )
                } as GenericListItem;
            }) ?? [],
        [selectedPlaylistTracks]
    );

    return (
        <div>
            <div className={classes.playlistToolbar}>
                <div>
                    {showBackToPlaylistsButton && (
                        <button className={classes.backToPlaylistsBtn} title={'Back to Playlists'} onClick={onBackToPlaylists}>
                            <i className="fa-solid fa-chevron-left"></i>
                        </button>
                    )}
                    <ItemPlaybar
                        itemType="Playlist"
                        viewMode="inline"
                        viewSize="medium"
                        onPlayClick={() => {
                            dispatch(playPlaylist(selectedPlaylistTracks));
                        }}
                        onPlusClick={() => {
                            dispatch(appendPlaylist(selectedPlaylistTracks));
                        }}
                    ></ItemPlaybar>
                </div>
                <span>{selectedPlaylist.name}</span>
                <span>
                    {isMyPlaylits && (
                        <>
                            <button className={classes.playlistToolbarSaveChnges}>Save Changes</button>
                            <MenuButton
                                buttonView="transparent"
                                buttonSize="medium"
                                //menuXPosition="Right"
                                menuSections={[
                                    {
                                        menuItems: [
                                            {
                                                text: 'Add Tracks',
                                                onClick: () => {
                                                    onChangePlaylistEditorMode('add-tracks');
                                                }
                                            },
                                            {
                                                text: 'Rename Playlist',
                                                onClick: () => {
                                                    onChangePlaylistEditorMode('rename');
                                                }
                                            },

                                            {
                                                text: 'Delete Playlist',
                                                onClick: () => {
                                                    onChangePlaylistEditorMode('delete');
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            ></MenuButton>
                        </>
                    )}
                </span>
            </div>
            <div className={classes.playlistTracks}>
                {playlistTracksAsListItems.length > 0 ? (
                    <GenericList items={[...playlistTracksAsListItems]} viewSize="medium"></GenericList>
                ) : isLoading ? (
                    <Preloader viewMode="Inline"></Preloader>
                ) : (
                    <div className={classes.emptyPlaylist}>Empty playlist</div>
                )}
            </div>
        </div>
    );
}

export default PlaylistTracks;
